import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { GoogleLogin } from '@react-oauth/google'

import { Images } from '@images'
import { CustomButton, PasswordInput, TextCaptionRegular, TextH2Bold, TextInput, TextLegalRegular } from '@components'
import { colors } from '@styles'
import { useFlash, useModal } from '@contexts'
import { useTranslation } from '@hooks'

import { ForgotPasswordInitModal } from '../../../ForgotPassword'
import { useLogin } from '../../../../hooks/useLogin'

const HeaderImageContainer = styled('div')(() => ({
  display: 'flex',
  height: 150,
  alignItems: 'center',
  alignSelf: 'stretch',
  flexGrow: 1,
}))

const HeaderImage = styled('div')(() => ({
  flex: '1 0 0',
  backgroundImage: `url(${Images.LoginImage})`,
  backgroundSize: 'cover',
  backgroundPositionY: 'center',
  backgroundRepeat: 'no-repeat',
  alignSelf: 'stretch',
}))

const Header = () => {
  const { t } = useTranslation()

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={2.5}>
      <HeaderImageContainer>
        <HeaderImage />
      </HeaderImageContainer>
      <TextH2Bold>{t('login.modal.title')}</TextH2Bold>
    </Box>
  )
}

const Accounts = () => {
  const { loginGoogle } = useLogin()
  const { showFlash } = useFlash()

  return (
    <Box
      display={'flex'}
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      alignSelf={'center'}
      gap={2}
      flexWrap={'wrap'}
      sx={{ ':hover': { cursor: 'pointer' } }}
    >
      <GoogleLogin
        onSuccess={async credentialResponse => {
          if (credentialResponse.credential) {
            loginGoogle({ token: credentialResponse.credential })
          }
        }}
        onError={() => {
          showFlash({ message: 'Login Failed', severity: 'error' })
        }}
      />
    </Box>
  )
}

const Line = styled('div')(() => ({
  height: 1,
  backgroundColor: colors.grayscale['30'].background,
  flex: '1 0 0',
}))

const Lines = () => {
  const { t } = useTranslation()

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={1.25}>
      <Line />
      <TextLegalRegular>{t('login.modal.or')}</TextLegalRegular>
      <Line />
    </Box>
  )
}

const Form = () => {
  const { showModal } = useModal()
  const { login } = useLogin()
  const { t } = useTranslation()

  const [form, updateForm] = useState({ username: '', password: '' })

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={1.5}>
      <TextInput
        label={t('login.modal.inputs.email')}
        fullWidth
        onChange={e => updateForm({ ...form, username: e.target.value })}
      />
      <PasswordInput
        label={t('login.modal.inputs.password')}
        fullWidth
        onChange={e => updateForm({ ...form, password: e.target.value })}
      />
      <CustomButton
        sx={{ lineHeight: 0.4 }}
        variant={'link'}
        label={t('login.modal.forgot-password')}
        onClick={() => showModal({ modal: <ForgotPasswordInitModal username={form.username} /> })}
      />
      <CustomButton variant={'primary'} label={t('login.modal.submit')} fullWidth={true} onClick={() => login(form)} />
    </Box>
  )
}

const CreateAccount = () => {
  const navigate = useNavigate()
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Box display={'flex'} alignItems={'center'} alignSelf={'stretch'} gap={0.5} paddingY={1}>
      <TextCaptionRegular sx={{ lineHeight: 0.4 }}>{t('login.modal.without-account')}</TextCaptionRegular>
      <CustomButton
        sx={{ lineHeight: 0.4 }}
        variant={'link'}
        label={t('login.modal.create-account')}
        onClick={() => {
          navigate('/sign-up')
          dismissModal()
        }}
      />
    </Box>
  )
}

const Body = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={2.5}>
      <Accounts />
      <Lines />
      <Form />
      <CreateAccount />
    </Box>
  )
}

const LoginModal = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      alignSelf={'stretch'}
      gap={3}
      paddingX={3}
      paddingBottom={3}
      flexGrow={1}
      sx={{ overflowY: 'auto' }}
    >
      <Header />
      <Body />
    </Box>
  )
}

export { LoginModal }
