import Box from '@mui/material/Box'
import { keyframes } from '@emotion/react'

import { TextH4Bold } from '@components'
import { BannerIcon } from '@features/Icons'
import { useTranslation } from '@hooks'

const scrolling = keyframes`
    0% { transform: translateX(100vw)}
    100% { transform: translateX(0)}
`
const Banner = (props: { scrollEnabeld?: boolean }) => {
  const { scrollEnabeld = false } = props
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: '#E30A17',
        justifyContent: scrollEnabeld ? undefined : 'center',
      }}
    >
      <Box
        sx={theme => ({
          padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
          display: 'flex',
          animation: scrollEnabeld ? `${scrolling} 15s linear infinite` : undefined,
          width: scrollEnabeld ? 'auto' : '100%',
          justifyContent: 'space-between',
          gap: scrollEnabeld ? 10 : 0,
        })}
      >
        <Box component={'span'} sx={{ height: 20, marginTop: -0.5, color: 'white' }}>
          <BannerIcon size={24} />
        </Box>
        <TextH4Bold
          sx={{
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          {t('landing.banner')}
        </TextH4Bold>
        <Box component={'span'} sx={{ height: 20, marginTop: -0.5, color: 'white' }}>
          <BannerIcon size={24} />
        </Box>
      </Box>
    </Box>
  )
}

export { Banner }
