import { styled, ThemeProvider } from '@mui/material/styles'
import { FormControl, FormHelperText, Popper } from '@mui/material'
import { DatePicker, DateView } from '@mui/x-date-pickers'
import * as React from 'react'
import dayjs from 'dayjs'
import { createTheme } from '@mui/material/styles'

import { colors } from '@styles'

import { LabelStyle } from '../styles'

interface DateInputProps {
  name: string
  label: string
  fullWidth?: boolean | undefined
  defaultValue?: dayjs.Dayjs
  openTo?: 'day' | 'month' | 'year'
  onChange?: (date: dayjs.Dayjs | null) => void
  error?: boolean
  helperText?: string | boolean | null
  format?: string
  views?: DateView[]
}

const CustomPopper = styled(Popper)(() => ({
  zIndex: 1300,
  '.MuiPickersPopper-paper': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
}))

const DateInput = (props: DateInputProps) => {
  const { error, helperText, ...otherProps } = props

  const newTheme = theme =>
    createTheme({
      ...theme,
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              overflow: 'hidden',
              color: theme.palette.primary.main,
              borderWidth: 1,
              borderColor: colors.grayscale['30'].background,
              borderRadius: theme.spacing(1.5),
              borderStyle: 'solid',
              backgroundColor: colors.grayscale['100'].background,
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              color: `${theme.palette.primary.main} !important`,
            },
          },
        },
      },
    })

  return (
    <FormControl className={'ShiDateInput-form-control'} fullWidth={props.fullWidth}>
      <ThemeProvider theme={newTheme}>
        <DatePicker<dayjs.Dayjs>
          className={'ShiDateInput-datepicker'}
          {...otherProps}
          sx={theme => ({
            minHeight: 'auto',
            width: props.fullWidth ? '100%' : 'auto',
            '.MuiInputLabel-root': LabelStyle(theme),
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(1.5),
              backgroundColor: colors.grayscale['10'].background,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grayscale['30'].background,
              borderWidth: 2,
            },
            ':hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.light,
              },
            },
          })}
          slotProps={{
            layout: {
              sx: theme => ({
                '.MuiDateCalendar-root': {
                  color: theme.palette.primary.main,
                  backgroundColor: colors.grayscale['100'].background,
                  height: 'auto',
                },
                '.MuiPickersYear-yearButton': {
                  color: theme.palette.primary.main,
                  '&.Mui-selected': {
                    borderRadius: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    border: '1px solid',
                    backgroundColor: colors.grayscale['70'].background,
                  },
                  '&:hover': {
                    borderRadius: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.light,
                    color: theme.palette.primary.light,
                    border: '1px solid',
                    backgroundColor: colors.grayscale['70'].background,
                  },
                },
                '.MuiPickersDay-today': {
                  borderRadius: theme.spacing(1),
                  borderWidth: 1,
                  borderColor: theme.palette.primary.main,
                },
                '.MuiDayCalendar-weekDayLabel': {
                  color: colors.grayscale['30'].background,
                },
                '.MuiPickersArrowSwitcher-button': {
                  color: theme.palette.primary.main,
                },
                '.MuiPickersCalendarHeader-switchViewButton': {
                  color: colors.grayscale['30'].background,
                },
                '.MuiPickersCalendarHeader-labelContainer': {
                  borderRadius: theme.spacing(1),
                  backgroundColor: theme.palette.primary.main,
                  color: colors.grayscale['80'].background,
                },
                '.MuiPickersCalendarHeader-switchViewIcon': {
                  color: colors.grayscale['80'].background,
                },
                '.MuiPickersDay-root': {
                  color: theme.palette.primary.main,
                  transition: 'none',

                  '&.Mui-selected': {
                    borderRadius: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    border: '1px solid',
                    backgroundColor: colors.grayscale['80'].background,
                    transition: 'none',
                  },
                  '&:hover': {
                    borderRadius: theme.spacing(1),
                    borderWidth: 1,
                    borderColor: theme.palette.primary.light,
                    color: theme.palette.primary.light,
                    border: '1px solid',
                    backgroundColor: colors.grayscale['80'].background,
                    transition: 'none',
                  },
                },
              }),
            },
          }}
          openTo={'year'}
          views={props.views ?? ['year', 'month', 'day']}
          format={props.format ?? 'DD/MM/YYYY'}
          slots={{
            popper: CustomPopper,
          }}
        />
      </ThemeProvider>

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export { DateInput }
