import Box from '@mui/material/Box'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { SxProps } from '@mui/material'

import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { TextBodyBold } from '../Text'

interface Props {
  dueDate: Date
  sx?: SxProps
}

const CountDownBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: colors.grayscale['10'].background,
  backgroundColor: colors.grayscale['60'].background,
  maxWidth: theme.spacing(24),
  minWidth: theme.spacing(20),
}))

const CountDownTimeItem = styled(TextBodyBold)(() => ({
  width: '24%',
  textAlign: 'center',
}))

const CountDownLabelItem = styled('div')(() => ({
  color: colors.grayscale['30'].background,
  fontSize: 10,
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'center',
  alignItems: 'center',
  width: '25%',
}))
const CountDown = (props: Props) => {
  const { dueDate, sx } = props
  const [now, updateNow] = useState(dayjs())
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setInterval(() => updateNow(dayjs()), 1000)

    return () => clearInterval(timer)
  }, [])

  const countDown = useMemo(() => {
    const diff = dayjs(dueDate).diff(now, 'second')
    const days = Math.floor(diff / (24 * 60 * 60))
    const hours = Math.floor((diff % (24 * 60 * 60)) / (60 * 60))
    const minutes = Math.floor((diff % (60 * 60)) / 60)
    const seconds = diff % 60

    return {
      days: days.toString().padStart(2, '0'),
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    }
  }, [dueDate, now])

  return (
    <CountDownBox display={'flex'} flexDirection={'column'} py={1} flexShrink={0} sx={sx}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        mx={2}
        pb={0.5}
        sx={{
          borderBottom: `2px solid ${colors.grayscale['30'].background}`,
        }}
      >
        <CountDownTimeItem>{countDown.days}</CountDownTimeItem>
        <CountDownTimeItem sx={{ width: '1%' }}>:</CountDownTimeItem>
        <CountDownTimeItem>{countDown.hours}</CountDownTimeItem>
        <CountDownTimeItem sx={{ width: '1%' }}>:</CountDownTimeItem>
        <CountDownTimeItem>{countDown.minutes}</CountDownTimeItem>
        <CountDownTimeItem sx={{ width: '1%' }}>:</CountDownTimeItem>
        <CountDownTimeItem>{countDown.seconds}</CountDownTimeItem>
      </Box>
      <Box display={'flex'} flexDirection={'row'} mx={2} pt={0.5}>
        <CountDownLabelItem>{t('countdown.days')}</CountDownLabelItem>
        <CountDownLabelItem>{t('countdown.hours')}</CountDownLabelItem>
        <CountDownLabelItem>{t('countdown.minutes')}</CountDownLabelItem>
        <CountDownLabelItem>{t('countdown.seconds')}</CountDownLabelItem>
      </Box>
    </CountDownBox>
  )
}

export { CountDown }
