import { Divider, popoverClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'

import { colors } from '@styles'

export const VerticalDivider = () => (
  <Divider orientation={'vertical'} color={colors.grayscale['50'].background} flexItem />
)
export const HorizontalDivider = () => (
  <Divider orientation={'horizontal'} color={colors.grayscale['50'].background} flexItem />
)

export const ProfileMenu = styled(Menu)(({ theme }) => ({
  [`.${popoverClasses.paper}`]: {
    borderRadius: theme.spacing(2),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    color: colors.grayscale[10].background,
  },
}))

export const NotificationsMenu = styled(Menu)(({ theme }) => ({
  [`.${popoverClasses.paper}`]: {
    borderRadius: theme.spacing(1.5),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    color: colors.grayscale[10].background,
  },
}))
