import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { ListItemText, Tab, Tabs, useMediaQuery } from '@mui/material'
import { SyntheticEvent, useState } from 'react'

import { useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'
import { Content, SelectInput, Title } from '@components'
import { Images } from '@images'
import { colors, MediaQuery, MqDesktop } from '@styles'

import { DatesRange, RankingType } from '../../types'
import {
  CollectiblesRankingTable,
  GlobalRankingTable,
  PredictionsRankingTable,
  TriviasRankingTable,
  ChallengesRankingTable,
  DigitalizationRankingTable,
} from '../../components'

const StyledTab = styled(Tab)(() => ({
  color: colors.grayscale['10'].background,
  '&.Mui-selected': {
    color: colors.grayscale['20'].background,
  },
}))

const RankingMain = () => {
  const { t } = useTranslation()
  const tabs = [
    RankingType.Global,
    RankingType.Predictions,
    RankingType.Trivias,
    RankingType.Challenges,
    RankingType.Collectibles,
    RankingType.Digitalization,
  ]
  const [type, setType] = useState(RankingType.Global)
  const [tab, setTab] = useState(0)
  const [datesRange, setDatesRange] = useState(DatesRange.CurrentWeek)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setType(tabs[newValue])
    setTab(newValue)
  }
  const isDesktop = useMediaQuery(MediaQuery[MqDesktop].replace(/^@media( ?)/m, ''))

  return (
    <AppBase requireActiveSession backgroundImage={Images.RankingBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('ranking.title')} />
        <Content flexDirection={'column'}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isDesktop ? 'row' : 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label='tabs'
              scrollButtons={false}
              variant={'scrollable'}
              sx={{
                borderBottom: 1,
                borderColor: colors.grayscale['30'].background,
                backgroundColor: colors.grayscale['80'].background,
                display: 'flex',
                flexGrow: 1,
                width: '100%',
                mr: isDesktop ? 4 : 0,
              }}
            >
              <StyledTab label={t('ranking.tabs.global')} id={'tab-global'} aria-controls={'tabpnel-global'} />
              <StyledTab
                label={t('ranking.tabs.predictions')}
                id={'tab-predictions'}
                aria-controls={'tabpanel-predictions'}
              />
              <StyledTab label={t('ranking.tabs.trivias')} id={'tab-trivias'} aria-controls={'tabpanel-trivias'} />
              <StyledTab
                label={t('ranking.tabs.challenges')}
                id={'tab-challenges'}
                aria-controls={'tabpanel-challenges'}
              />
              <StyledTab
                label={t('ranking.tabs.collectibles')}
                id={'tab-collectibles'}
                aria-controls={'tabpanel-collectibles'}
              />
              <StyledTab
                label={t('ranking.tabs.digitalization')}
                id={'tab-digitalization'}
                aria-controls={'tabpanel-digitalization'}
              />
            </Tabs>
            <Box sx={{ width: isDesktop ? 'auto' : '100%' }}>
              <SelectInput
                label={''}
                options={[DatesRange.CurrentWeek, DatesRange.CurrentMonth, DatesRange.CurrentSeason, DatesRange.Total]}
                itemProvider={(option: DatesRange) => <ListItemText primary={t(`ranking.dates-range.${option}`)} />}
                objectToString={(option?: DatesRange) => (option ? t(`ranking.dates-range.${option}`) : undefined)}
                value={[datesRange]}
                multiple={false}
                onChange={values => {
                  setDatesRange(values[0])
                }}
                sx={{ minHeight: 'auto', width: '100%' }}
              />
            </Box>
          </Box>
          {type === RankingType.Global && <GlobalRankingTable datesRange={datesRange} />}
          {type === RankingType.Predictions && <PredictionsRankingTable datesRange={datesRange} />}
          {type === RankingType.Trivias && <TriviasRankingTable datesRange={datesRange} />}
          {type === RankingType.Challenges && <ChallengesRankingTable datesRange={datesRange} />}
          {type === RankingType.Collectibles && <CollectiblesRankingTable datesRange={datesRange} />}
          {type === RankingType.Digitalization && <DigitalizationRankingTable datesRange={datesRange} />}
        </Content>
      </Box>
    </AppBase>
  )
}

export { RankingMain }
