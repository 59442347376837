import Box from '@mui/material/Box'
import * as React from 'react'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Title, ItemImages, Content } from '@components'
import { useTranslation } from '@hooks'
import { Images } from '@images'
import { useModal, useUser } from '@contexts'
import { AppBase } from '@features/AppBase'
import { useCheckQuota } from '@features/Quotas/hooks'
import { QuotaFeature } from '@features/Quotas/types'
import { ROUTES } from '@constants'

import { Form, FormImages } from '../../components'
import { DigitizeProvider, useDigitize } from '../../contexts/digitize-context'
import { LoginModal } from '../../../Login'

const ImagePreviewAndFormsWrapper = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
      gap={4}
      justifyContent={'space-evenly'}
      alignItems={{ xl: 'flex-start', lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' }}
      flexGrow={1}
      flexShrink={1}
      width={'100%'}
    >
      {props.children}
    </Box>
  )
}

const ItemImagesContainer = () => {
  const { itemMediaFiles, signed } = useDigitize()

  const images = useMemo(() => {
    const result: string[] = []
    const { signatureImage, ...previewImages } = itemMediaFiles
    for (const image of Object.values(previewImages)) {
      result.push(!!image ? image : Images.TShirtPlaceholder)
    }
    if (signed) {
      result.push(!!signatureImage ? signatureImage : Images.TShirtPlaceholder)
    }

    return result
  }, [itemMediaFiles, signed])

  return <ItemImages images={images} />
}

const DigitizeMain = () => {
  const { t } = useTranslation()
  const { showModal, isOpen } = useModal()
  const { userState } = useUser()
  const navigate = useNavigate()
  const [checkState, setCheckState] = useState(false)
  useCheckQuota(QuotaFeature.DIGITIZE, {
    redirect: () => navigate(ROUTES.HOME),
  })
  useEffect(() => {
    if (!userState && !isOpen && !checkState) {
      showModal({
        modal: <LoginModal />,
        options: {
          onDismiss: () => {
            setCheckState(true)
          },
        },
      })
    }
  }, [userState, isOpen, checkState])

  useEffect(() => {
    if (checkState) {
      if (!userState) navigate('/')
      else setCheckState(false)
    }
  }, [userState, checkState])

  return (
    <DigitizeProvider>
      <AppBase requireActiveSession={!!userState} backgroundImage={Images.DigitizeBackground}>
        <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
          <Title text={t('digitize.title')} />
          <Content
            sx={{ flexWrap: 'nowrap', justifyContent: 'flex-start' }}
            flexDirection={{ xl: 'row', xs: 'column' }}
            alignItems={{ xl: 'flex-start', xs: 'center' }}
          >
            <ImagePreviewAndFormsWrapper>
              <ItemImagesContainer />
              <FormImages />
            </ImagePreviewAndFormsWrapper>
            <Form />
          </Content>
        </Box>
      </AppBase>
    </DigitizeProvider>
  )
}

export { DigitizeMain }
