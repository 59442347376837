import * as React from 'react'
import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'

import { CustomButton, TextTitleBold } from '@components'
import { PropsWithGroupedCollectible } from '@types'
import { Images } from '@images'
import { useMetadataToText, useTranslation } from '@hooks'
import { MediaQuery, MqTablet } from '@styles'

import {
  GroupedCollectibleBadge,
  GroupedCollectibleCardBodyBlock,
  GroupedCollectibleCardBottom,
  GroupedCollectibleCardContentBlock,
  GroupedCollectibleCardImage,
} from './styled'

const GroupedCollectibleCard = (props: PropsWithGroupedCollectible<{ onClick?: () => void }>) => {
  const { groupedCollectible, onClick } = props
  const { getName } = useMetadataToText()
  const { t } = useTranslation()

  const isTabletOrDesktop = useMediaQuery(MediaQuery[MqTablet].replace(/^@media( ?)/m, ''))

  const name = useMemo(() => {
    const itemName = getName(groupedCollectible)
    const lenLimit = isTabletOrDesktop ? 35 : 22
    if (itemName.length > lenLimit) {
      return itemName.slice(0, lenLimit - 3) + '...'
    }

    return itemName
  }, [groupedCollectible, isTabletOrDesktop])

  return (
    <GroupedCollectibleCardContentBlock onClick={onClick} sx={{ width: isTabletOrDesktop ? undefined : '100%' }}>
      <GroupedCollectibleCardBodyBlock id={'body'}>
        <GroupedCollectibleBadge badgeContent={groupedCollectible?.collectibles.length}>
          <GroupedCollectibleCardImage
            src={groupedCollectible?.mediaFiles.frontImage ?? Images.TShirtPlaceholder}
            id={'image'}
            sx={{ width: isTabletOrDesktop ? undefined : '100%' }}
          />
        </GroupedCollectibleBadge>
        <TextTitleBold>{name}</TextTitleBold>
      </GroupedCollectibleCardBodyBlock>
      <GroupedCollectibleCardBottom id={'actions'}>
        <CustomButton variant={'primary'} label={t('collectible.actions.view')} fullWidth onClick={onClick} />
      </GroupedCollectibleCardBottom>
    </GroupedCollectibleCardContentBlock>
  )
}

export { GroupedCollectibleCard }
