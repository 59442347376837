import * as React from 'react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { CustomButton, TextTitleBold } from '@components'
import { colors, MediaQuery, MqTablet } from '@styles'
import { Item, ItemStatus, PropsWithItem } from '@types'
import { Images } from '@images'
import { useMetadataToText, useTranslation } from '@hooks'
import { ROUTES } from '@constants'

import { ItemCardBodyBlock, ItemCardBottom, ItemCardContentBlock, ItemCardImage } from './styled'

const ItemCard = (props: PropsWithItem<{ onClick?: (item: Item) => void }>) => {
  const { item, onClick } = props
  const navigate = useNavigate()
  const { getName } = useMetadataToText()
  const { t } = useTranslation()
  const borderColor = useMemo(() => {
    switch (item?.status) {
      case ItemStatus.VALIDATED:
        return colors.primary.main.background
      case ItemStatus.REVIEW:
      case ItemStatus.USERREVIEW:
        return colors.grayscale[30].background
      case ItemStatus.INCOMPLETE:
        return colors.status.error.background
      default:
        return colors.grayscale[30].background
    }
  }, [item?.status])

  const actions = useMemo(() => {
    switch (item?.status) {
      case ItemStatus.VALIDATED:
        return (
          <CustomButton
            variant={'primary'}
            label={t('item.actions.view')}
            fullWidth
            onClick={onClick ? () => onClick(item) : () => navigate(`${ROUTES.MY_SHIRTS}/${item.id}`)}
          />
        )
      case ItemStatus.REVIEW:
      case ItemStatus.USERREVIEW:
        return null
      case ItemStatus.INCOMPLETE:
        return (
          <CustomButton
            variant={'primary'}
            label={t('item.actions.edit')}
            fullWidth
            onClick={() => navigate(`${ROUTES.DIGITIZE}/edit/${item.id}`)}
          />
        )

      default:
        return null
    }
  }, [item?.status])

  const isTabletOrDesktop = useMediaQuery(MediaQuery[MqTablet].replace(/^@media( ?)/m, ''))

  const name = useMemo(() => {
    const itemName = getName(item?.metadata)
    const lenLimit = isTabletOrDesktop ? 35 : 22
    if (itemName.length > lenLimit) {
      return itemName.slice(0, lenLimit - 3) + '...'
    }

    return itemName
  }, [item, isTabletOrDesktop])

  return (
    <ItemCardContentBlock sx={{ borderColor, width: isTabletOrDesktop ? undefined : '100%' }}>
      <ItemCardBodyBlock id={'body'}>
        <ItemCardImage
          src={item?.metadata.mediaFiles.frontImage ?? Images.TShirtPlaceholder}
          id={'image'}
          sx={{ width: isTabletOrDesktop ? undefined : '100%' }}
        />
        <TextTitleBold sx={{ wordBreak: 'break-all' }}>{name}</TextTitleBold>
      </ItemCardBodyBlock>
      <ItemCardBottom id={'actions'}>{actions}</ItemCardBottom>
    </ItemCardContentBlock>
  )
}

export { ItemCard }
